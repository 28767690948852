<template>
    <v-container>
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>

        <div class="mb-3">
          <v-toolbar>
            <v-toolbar-title>E-Voucher Detail</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div> 
        <v-row class="mt-3">
           <v-col cols="12" md="12">
                <v-card>
                    <v-toolbar dark class="success">
                        <v-toolbar-title>Customer Info</v-toolbar-title>
                    </v-toolbar>
                    <v-container class="pt-2 mt-3">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field readonly v-for="(info,index) in  custInfo" :key="'custIndex'+index" outlined v-model="custDetail[info.fieldName]" :label="info.name"></v-text-field>
                            </v-col>
                        </v-row>

                    </v-container>
                
                </v-card>
            </v-col>
            <!--image-->
            <v-col cols="12" md="12">
                <v-card>
                    <v-toolbar dark class="warning">
                        <v-toolbar-title>E-Voucher Info</v-toolbar-title>
                    </v-toolbar>

                    <v-container class="pt-2 mt-3">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field readonly v-for="(info,index) in  txInfo" :key="info.name + index" outlined v-model="txDetail[info.fieldName]" :label="info.name"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>

        </v-row>

    </v-container>  
</template>
    
<script>
import DlgMessage from '@/components/DlgMessage'
import MpRdEvoucherService from "@/services/MpRdEvoucherService"
export default {
  components:{DlgMessage},
  props: {
       caller: String
    },
  data() {
    return{
            //breadcrumb
            items: [
              {text:'E-Voucher',disabled: false,href:'/',},
              {text:'Detail',disabled: false,href:'#',},
              ],
            statusList:[],
            form:[],
            custDetail:[],
            custInfo:[
                {name:"Login Id",fieldName:"login_id"},
                {name:"Full Name",fieldName:"full_name"},
                {name:"Cell Phone No.",fieldName:"cell_phone"},
                {name:"Email Address.",fieldName:"email"},
            ],
            txList:[],
            txDetail:[],
            txInfo:[
                {name:"Name",fieldName:"ci_name"},
                {name:"Serial Number",fieldName:"serial_number"},
                {name:"E-Voucher #",fieldName:"evoucher_no"},
                {name:"Value",fieldName:"ci_value"},
                {name:"Redeemed Date",fieldName:"redeemed_date"},
                {name:"Redeemed By",fieldName:"redeemed_by"},
                {name:"Terminal ID",fieldName:"edc_login_id"},
                {name:"Process Reference No.",fieldName:"process_reference_no"},
            ],
            routeParam:[],
    }
  },
  async created() {
      this.routeParam['process_reference_no'] = this.$route.params.process_reference_no;
      this.viewDetail();
  },
  
  methods:{

    async viewDetail(){
        let request = {process_reference_no:this.routeParam['process_reference_no']}
        try{
            MpRdEvoucherService.view_rd_evoucher(request).then((res) => {
                //console.log(res.data.data);
                this.custDetail = res.data.custData;
                this.txDetail = res.data.data;

            }).catch((e)=>{
                //console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error','E-Voucher',e.response);
            })
            ;
        } catch(e) {
            //console.log(e);
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }

    },
    dlgClose(){
        this.visible = false;        
    },
  }
}
</script>