import axios from "axios"

let vlogin_id = localStorage.getItem('user_login_id');
class MpRdEvoucherService {


    list_recent() {
        //axios.headers.common['Token-Auth'] = localStorage.getItem('mtoken_auth');
        //console.log('axios',axios);
        let form = {user_login_id:vlogin_id};
        
        return axios.post("mp_rd_evoucher/list_recent", form);

    }

    view_rd_evoucher(form) {
        form['user_login_id'] = vlogin_id;
        return axios.post("mp_rd_evoucher/view_rd_evoucher", form);

    }  
    
    search_rd_evoucher(form) {
        form['user_login_id'] = vlogin_id;
        return axios.post("mp_rd_evoucher/search_rd_evoucher", form);

    }
    
    list_reimbursement(form) {
        form['user_login_id'] = vlogin_id;
        return axios.post("mp_rd_evoucher/list_reimbursement", form);

    }

    view_reimbursement(form) {
        form['user_login_id'] = vlogin_id;
        return axios.post("mp_rd_evoucher/view_reimbursement", form);

    }
    req_reimbursement(form) {
        form['user_login_id'] = vlogin_id;
        return axios.post("mp_rd_evoucher/req_reimbursement", form);

    }

    list_outlet(form) {
        form['user_login_id'] = vlogin_id;
        return axios.post("mp_rd_evoucher/list_outlet", form);

    }
    
    
    
}
    
 

export default new MpRdEvoucherService();